<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            Upload File
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-4">
                <v-row no-gutters>
                    <v-text-field
                        v-model="trm"
                        type="number"
                        label="TRM *"
                        prepend-icon="mdi-currency-usd"
                        :rules="[rules.required]"
                        required
                    >
                    </v-text-field>
                </v-row>
                <v-row no-gutters>
                    <v-file-input
                        truncate-length="15"
                        v-model="file"
                        placeholder="File *"
                        accept=".xlsx"
                        hide-details
                        required
                        :rules="[rules.required]"
                    ></v-file-input>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                rounded
                :loading="loading"
                class="mb-3"
                @click="readFile"
                :disabled="!valid"
            >
                IMPORT
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import API from '@/services/api'

export default {
    name: 'UploadPOC',
    data: () => ({
        loading: false,
        valid: false,
        file: null,
        rules: {
            required: v => !!v || 'invalid value',
        },
        pocData: [],
        trm: 1,
        estimatedMargin: 0,
        pocs: [],
        selectedYear: '',
        selectedMonth: '',
        defaultEstimatedMargin: 25,
    }),
    mounted() {
        const options = { timeZone: 'America/Bogota', timeZoneName: 'short' }
        const formatter = new Intl.DateTimeFormat('en-US', options)
        const currentDate = formatter.format(new Date())
        this.selectedYear = currentDate.slice(6, 10)
        this.selectedMonth = currentDate.slice(0, 2)
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async createPOC(items, projects) {
            try {
                this.loading = true
                const newItems = []
                for (const item of items) {
                    const project = projects.find(
                        p => '' + p.sapCode == '' + item['CO']
                    )
                    if (!project) {
                        continue
                    }
                    const estimated_revenues =
                        project.price - project.b2b + project.b2b * this.trm
                    const estimated_gross_profit =
                        estimated_revenues *
                        ((project.estimatedMargin ||
                            this.defaultEstimatedMargin) /
                            100)
                    const estimated_cost = Math.abs(
                        estimated_revenues - estimated_gross_profit
                    )
                    const percentage_of_completition =
                        ((item['Costs'] || 0) + (item['Nómina'] || 0)) /
                        estimated_cost
                    const revenues_earned =
                        percentage_of_completition * estimated_revenues
                    const gross_profit =
                        revenues_earned -
                        ((item['Costs'] || 0) + (item['Nómina'] || 0))
                    let valuesForYear = this.pocs.reduce(
                        (accumulator, poc) => {
                            if (
                                poc.year == this.selectedYear &&
                                poc.month == this.selectedMonth
                            ) {
                                return accumulator
                            }
                            if (poc.year == this.selectedYear) {
                                accumulator.total_revenues +=
                                    poc.revenues_earned
                                accumulator.total_gross_profit +=
                                    poc.gross_profit
                                accumulator.cost_of_revenues +=
                                    poc.costs + poc.nomina
                            }
                            return accumulator
                        },
                        {
                            total_revenues: 0,
                            total_gross_profit: 0,
                            cost_of_revenues: 0,
                        }
                    )
                    const cost_of_revenues =
                        valuesForYear.cost_of_revenues +
                        ((item['Costs'] || 0) + (item['Nómina'] || 0))
                    const percentage_completed =
                        cost_of_revenues / estimated_cost
                    let data = {
                        job: project.name,
                        client: project.clientData
                            ? project.clientData.name
                            : project.client,
                        project_id: project.reference,
                        orden_CO: item['CO'],
                        currency: project.currency,
                        total_amount_before_taxes: project.price,
                        balance_to_bill: project.b2b,
                        costs: item['Costs'] || 0,
                        nomina: item['Nómina'] || 0,
                        billed_to_date: item['Billed to Date'] || 0,
                        estimated_revenues,
                        estimated_margin:
                            project.estimatedMargin ||
                            this.defaultEstimatedMargin,
                        estimated_gross_profit,
                        estimated_cost,
                        percentage_of_completition,
                        revenues_earned,
                        gross_profit,
                        [`total_revenues_${this.selectedYear}_for_project`]:
                            valuesForYear.total_revenues + revenues_earned,
                        [`total_gross_profit_${this.selectedYear}_for_project`]:
                            valuesForYear.total_gross_profit + gross_profit,
                        [`cost_of_revenues_(COP)`]: cost_of_revenues,
                        percentage_completed,
                        [`revenues_earned_(COP)`]:
                            estimated_revenues * percentage_completed,
                        [`gross_profit_(COP)`]:
                            estimated_revenues * percentage_completed -
                            cost_of_revenues,
                        ['billed_to_date_for_this_month']:
                            project.price - project.b2b,
                        ['estimated_cost_to_complete_(COP)']:
                            estimated_cost - cost_of_revenues,
                        year: this.selectedYear,
                        month: this.selectedMonth,
                        trm_used: this.trm,
                    }
                    newItems.push(data)
                }
                const response = await API.createPOC({ items: newItems })
                this.$emit('addPOC', response)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getProjects(items) {
            try {
                this.loading = true
                const projects = await API.getProjectsBySapCode({
                    codes: items.map(item => item['CO']),
                })
                return projects
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async readFile() {
            try {
                this.loading = true
                const data = await this.file.arrayBuffer()
                /* data is an ArrayBuffer */
                const workbook = XLSX.read(data)
                var first_sheet_name = workbook.SheetNames[0]
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name]
                const items = XLSX.utils.sheet_to_json(worksheet, {
                    raw: true,
                })
                const projects = await this.getProjects(items)
                this.createPOC(items, projects)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
